import { left, right, type Either, type Node, type NodeServiceClient } from "~/lib/deps";
import { type FeaturingModel } from "~/lib/models";
import { ApiClientError, type ApiError } from "./apiError";

export class FeaturingService {
  readonly #client: NodeServiceClient;
  readonly #tenant: string;

  constructor(c: NodeServiceClient, t: string) {
    this.#client = c;
    this.#tenant = t;
  }

  public async listFeaturing(): Promise<Either<ApiError, FeaturingModel[]>> {
    const featuringOrError = await this.#client.query([["aspects", "contains", "featuring"]], 2, 1);

    if (featuringOrError.isLeft()) {
      console.error("Errors catched while fetching featuring", featuringOrError.value);
      return left(new ApiClientError());
    }

    const featuring = featuringOrError.value.nodes;

    const featuringImages = await this.getFeaturingImages(featuring);

    const featuringWithImages = featuring.map((f: Node, index: number) => ({
      node: f,
      images: featuringImages[index],
    }));

    return right(featuringWithImages.map(({ node, images }) => this.fromApi({ node, images })));
  }

  private async getFeaturingImages(featuresMe: Node[]) {
    const featuringImagesAndErrors = await Promise.all(
      featuresMe.map((f) => this.getFeaturingImagesUrlFrom(f.uuid))
    );

    const featuringImages = featuringImagesAndErrors
      .filter((result) => result.isRight())
      .map((node) => node.value);

    const featuringErrors = featuringImagesAndErrors
      .filter((result) => result.isLeft())
      .map((node) => node.value);

    if (featuringErrors.length > 0)
      console.error(
        "This errors were catched ehile fetching featuring images",
        featuringErrors.join(",")
      );

    return featuringImages;
  }

  private fromApi({ node, images }: { node: Node; images: Node[] | ApiError }): FeaturingModel {
    if (node.properties && images instanceof Array)
      return {
        name: node.title,
        bio: node.properties["featuring:bio"] as string,
        social: [
          {
            linkTo: node.properties["featuring:facebook"] as string,
            network: "facebook",
          },
          {
            linkTo: node.properties["featuring:instagram"] as string,
            network: "instagram",
          },
        ],
        profession: node.properties["featuring:profession"] as string,
        imagesUrl: images.map(
          (i) => new URL(this.#client.getNodeUrl(i.uuid).concat(`?x-tenant=${this.#tenant}`))
        ),
      };

    return {} as FeaturingModel;
  }

  private async getFeaturingImagesUrlFrom(uuid: string): Promise<Either<ApiError, Node[]>> {
    const imagesOrError = await this.#client.list(uuid);

    if (imagesOrError.isLeft()) {
      console.log("Errors catched while fetching featuring images", imagesOrError.value);
      return left(new ApiClientError());
    }

    return right(imagesOrError.value);
  }
}
